import { newLogEntry, sendSystemMessage } from "../redux/index";
import store from "../redux/store";
import preval from "preval.macro";
import { DateTime } from "luxon";
import { Box } from "@mui/material";
import { t } from "i18next";

export function addLogEntry(values) {
  const buildTimestamp = preval`module.exports = new Date().getTime();`;
  const buildDateTime = DateTime.fromMillis(buildTimestamp).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS
  );
  store.dispatch(newLogEntry({ ...values, version: buildDateTime }));
}

export function stringToHslColor(str, s = 80, l = 30) {
  if (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }
  return "hsl(0, 0%, 75%)";
}

export function hexToRGB(hex, alpha) {
  if (hex) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + parseFloat(alpha) + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  } else {
    return "";
  }
}

export function createSystemMessage(values) {
  // create messages depending on several states
  // receives: recipientId
  //           messageType
  //           senderMail

  let mFromUserId = "";
  let mToUserId = "";
  let mSenderMail = "";
  let mSubject = "";
  let mText = "";
  let mStartDate = "";
  let mEndDate = "";
  let mNumDays = 0;
  let mAllowNewPassword = false;
  let mAllowAccess = false;
  switch (values.messageType) {
    case "newAccount":
      mFromUserId = "0";
      mToUserId = "-1";
      mSenderMail = values.senderMail;
      mSubject = "Neue Anmeldung";
      mText =
        "Es wurde versucht, sich mit der Email-Adresse <br /><b>" +
        mSenderMail +
        "</b><br /> anzumelden.<br /><br />";
      mText +=
        "Wenn es sich um einen unberechtigten Anmeldeversuch handelt, müssen Sie nichts weiter unternehmen. Weitere Anmeldungen ";
      mText += "mit dieser Email-Adresse sind blockiert.<br />";
      mText +=
        'Andernfalls klicken Sie auf "Zugriff erlauben". Der Benutzer darf sich dann anmelden und wird sofort aufgefordert, ';
      mText +=
        "sich ein neues Passwort zu vergeben. Der Benutzer erhält eine entsprechende Email.";
      mAllowNewPassword = false;
      mAllowAccess = true;
      break;
    case "newPassword":
      mFromUserId = "0";
      mToUserId = "-3";
      mSenderMail = values.senderMail;
      mSubject = "Neues Passwort";
      mText =
        "Der Benutzer mit der Email-Adresse <br /><b>" +
        mSenderMail +
        "</b><br /> gibt an, sein Passwort vergessen zu haben.<br /><br />";
      mText +=
        'Klicken Sie auf "Neues Passwort erlauben", so dass der Benutzer bei seinem nächsten Anmeldeversuch aufgefordert wird, ';
      mText +=
        "sich ein neues Passwort zu vergeben und informieren Sie ihn darüber.";
      mText +=
        "Im anderen Fall unternehmen Sie nichts. Der Zugang für diesen Benutzer wurde automatisch gesperrt.";
      mAllowNewPassword = true;
      mAllowAccess = false;
      break;
    default:
  }

  store.dispatch(
    sendSystemMessage({
      fromUserId: mFromUserId,
      toUserId: mToUserId,
      title: mSubject,
      message: mText,
      messageMailAdress: mSenderMail,
      startDate: mStartDate,
      endDate: mEndDate,
      numDays: mNumDays,
      allowAccess: mAllowAccess,
      allowNewPassword: mAllowNewPassword,
    })
  );
}

export function easterDate(Y) {
  var C = Math.floor(Y / 100);
  var N = Y - 19 * Math.floor(Y / 19);
  var K = Math.floor((C - 17) / 25);
  var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor(I / 30);
  I =
    I -
    Math.floor(I / 28) *
      (1 -
        Math.floor(I / 28) *
          Math.floor(29 / (I + 1)) *
          Math.floor((21 - N) / 11));
  var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  var L = I - J;
  var M = 3 + Math.floor((L + 40) / 44);
  var D = L + 28 - 31 * Math.floor(M / 4);

  return DateTime.fromObject({ year: Y, month: M, day: D });
}

export function fourthAdventDate(Y) {
  var christmas = DateTime.fromObject({ year: Y, month: 12, day: 25 });
  return christmas.minus({ days: christmas.weekday });
}

export function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function hasPermission(right) {
  const effectiveRights = store.getState().loggeduser?.LoggedUser?.rights;
  return effectiveRights?.includes(right);
}

export function getGlobalSetting(component, key) {
  const settings = store.getState().settings.GlobalSettings;
  const setting = settings[component]?.settings || {};
  return setting[key]?.toString() || null;
}

export function getPersonalSetting(component, key) {
  const settings = store.getState().settings.PersonalSettings;
  const setting = settings[component]?.settings || {};
  return setting[key]?.toString() || null;
}

export function getDisplaySetting(component, key) {
  if (component) {
    const settings = store.getState().settings.DisplaySettings;
    const setting =
      settings?.find((S) => S.component.toString() === component.toString())
        ?.settings || {};
    // const setting = settings[component]?.settings || {};
    return setting ? setting[key]?.toString() : null;
  } else {
    return "";
  }
}

export function sortByDate(a, b) {
  if (!a || !b) return 0;
  var sortA = DateTime.fromSQL(a);
  var sortB = DateTime.fromSQL(b);
  var result = sortA < sortB ? -1 : sortA > sortB ? 1 : 0;
  return result;
}

export function getAge(dateString) {
  var today = DateTime.now();
  var birthDate = DateTime.fromSQL(dateString);
  return today.diff(birthDate, ["years", "days"]).toObject().years;
}

export function isOdd(num) {
  return num % 2 === 1;
}

export const nextOccurence = (state) => {
  var currentYear = DateTime.now().toFormat("y");
  var date;
  var calculatedDate;
  if (state.hasFixedDate && state.fixedMonth && state.fixedDay) {
    calculatedDate = DateTime.fromObject({
      year: currentYear,
      month: state.fixedMonth,
      day: state.fixedDay,
    });
  } else {
    switch (state.referenceDay) {
      case "easter":
        date = easterDate(currentYear);
        calculatedDate = date.plus({ days: state.referenceNumber });
        if (
          DateTime.now().startOf("day").diff(calculatedDate, "day").toObject()
            .days > 0
        ) {
          date = easterDate(parseInt(currentYear) + 1);
          calculatedDate = date.plus({
            days: parseInt(state.referenceNumber),
          });
        }
        break;
      case "fourthadvent":
        date = fourthAdventDate(currentYear);
        calculatedDate = date.plus({ days: state.referenceNumber });
        if (
          DateTime.now().startOf("day").diff(calculatedDate, "day").toObject()
            .days > 0
        ) {
          date = fourthAdventDate(parseInt(currentYear) + 1);
          calculatedDate = date.plus({ days: state.referenceNumber });
        }
        break;
      default:
        calculatedDate = DateTime.now();
    }
  }
  if (
    DateTime.now().startOf("day").diff(calculatedDate, "day").toObject().days >
    0
  ) {
    calculatedDate = calculatedDate.plus({ years: 1 });
  }
  return calculatedDate;
};

export function timeDiff(timeString1, timeString2) {
  const minutes1 =
    parseInt(timeString1.substring(0, 2)) * 60 +
    parseInt(timeString1.substring(3, 5));
  const minutes2 =
    parseInt(timeString2.substring(0, 2)) * 60 +
    parseInt(timeString2.substring(3, 5));
  const minutes = Math.abs(minutes1 - minutes2);
  const tHours = Math.floor(minutes / 60);
  const tMinutes = minutes - tHours * 60;
  return (
    tHours.toString().padStart(2, "0") +
    ":" +
    tMinutes.toString().padStart(2, "0")
  );
}

export function minutesToTime(minutes) {
  if (minutes === undefined || minutes === null) {
    return "--:--";
  }
  const minus = minutes < 0 ? "-" : "";
  const absMinutes = Math.abs(minutes === null ? 0 : minutes);
  var tHours = Math.floor(absMinutes / 60);
  var tMinutes = absMinutes % 60;
  if (Number.isNaN(tHours) || Number.isNaN(tMinutes)) {
    return "--:--";
  }
  return (
    minus +
    tHours.toString().padStart(2, "0") +
    ":" +
    tMinutes.toString().padStart(2, "0")
  );
}

export function minutesToSignedTime(minutes) {
  if (minutes === undefined || minutes === null) {
    return "";
  }
  const sign = minutes < 0 ? "-" : minutes > 0 ? "+" : "";
  const absMinutes = Math.abs(minutes === null ? 0 : minutes);
  var tHours = Math.floor(absMinutes / 60);
  var tMinutes = absMinutes % 60;
  return (
    sign +
    tHours.toString().padStart(2, "0") +
    ":" +
    tMinutes.toString().padStart(2, "0")
  );
}

export function minutesToShowTime(minutes, blink = false, preText = "") {
  if (minutes === undefined || minutes === null) {
    return <Box>{"--:--"}</Box>;
  } else {
    var separator = ":";
    const minus = minutes < 0 ? "-" : "";
    const absMinutes = Math.abs(minutes === null ? 0 : minutes);
    var tHours = Math.floor(absMinutes / 60);
    var tMinutes = absMinutes % 60;
    var tSeconds = parseInt(DateTime.now().toFormat("s"));
    if (
      Number.isNaN(tHours) ||
      Number.isNaN(tMinutes) ||
      Number.isNaN(tSeconds)
    ) {
      return <Box> {"--:--"}</Box>;
    }
    return (
      <Box>
        <Box component="span" sx={{ color: "white" }}>
          {preText}
        </Box>
        {minus + tHours.toString().padStart(2, "0")}
        <Box
          component="span"
          sx={{ color: blink && isOdd(tSeconds) ? "transparent" : "inherit" }}
        >
          {separator}
        </Box>
        {tMinutes.toString().padStart(2, "0")}
      </Box>
    );
  }
}

export function minutesFromTime(time) {
  const hours = parseInt(time.substring(0, 2));
  const minutes = parseInt(time.substring(3, 5));
  return hours * 60 + minutes;
}

export function timeToMinutes(timeString) {
  var minutes = parseInt(timeString.substring(3, 5));
  minutes += parseInt(timeString.substring(0, 2)) * 60;
  return minutes;
}

export function timeToReadableTime(timeString) {
  const parseString = timeString.toString();
  var hours = 0;
  var minutes = 0;
  if (parseString.length === 5) {
    hours = parseInt(parseString.substring(0, 2));
    minutes = parseInt(parseString.substring(3, 5));
  } else {
    minutes = parseInt(parseString);
  }
  var readableTime = "";
  if (hours > 0) {
    readableTime += hours + " " + (hours === 1 ? t("hour") : t("hours"));
  }
  if (minutes > 0) {
    readableTime +=
      " " + minutes + " " + (minutes === 1 ? t("minute") : t("minutes"));
  }
  return readableTime;
}

export function between(v, min, max) {
  return v >= min && v <= max;
}

/* Generator Function for itearation over days in luxon */
export function* days(interval) {
  let cursor = interval.start.startOf("day");
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

export function sendMailNotification(title) {
    var options = {
      body: title,
      icon: '/images/mail_icon.png'
    };

    new Notification("SOMURA: " + t("Unread messages"), options);
  };
