import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import UnauthenticatedRoute from "./Components/UnauthenticatedRoute";
import Loader from "./Components/Loader";

function makeLazyComponent(loader) {
  const AsyncComponent = lazy(loader);
  const LoadableComponent = (props = {}) => (
    <Suspense fallback={<Loader />}>
      <AsyncComponent {...props} />
    </Suspense>
  );
  return LoadableComponent;
}

const SignIn = makeLazyComponent(() => import("SignIn/SignIn"));
const NewPassword = makeLazyComponent(() => import("SignIn/NewPassword"));
const Dashboard = makeLazyComponent(() => import("Dashboard/Dashboard"));
const MonthCalendar = makeLazyComponent(() =>
  import("Calendars/MonthCalendar/MonthCalendar")
);
const YearCalendar = makeLazyComponent(() =>
  import("Calendars/YearCalendar/YearCalendar")
);
// const AbsenceCalendar = makeLazyComponent(() =>
//   import("Calendars/AbsenceCalendar/YearCalendar")
// );
const Persons = makeLazyComponent(() => import("MasterData/Persons/Persons"));
const TimeTracking = makeLazyComponent(() =>
  import("MasterData/TimeTracking/TimeTracking")
);
const Articles = makeLazyComponent(() =>
  import("MasterData/Articles/Articles")
);
const Projects = makeLazyComponent(() =>
  import("MasterData/Projects/ProjectsTable")
);
const OffDayTypes = makeLazyComponent(() =>
  import("Settings/OffDayTypes/OffDayTypes")
);
const Messages = makeLazyComponent(() => import("Messages/Messages"));
const CompanySettings = makeLazyComponent(() =>
  import("Settings/Company/Company")
);
const Addresses = makeLazyComponent(() =>
  import("Settings/Addresses/Addresses")
);
const CalendarSettings = makeLazyComponent(() =>
  import("Settings/Calendar/Calendar")
);
const ArticleSettings = makeLazyComponent(() =>
  import("Settings/Articles/Articles")
);
const GlobalSettings = makeLazyComponent(() =>
  import("Settings/GlobalSettings/GlobalSettings")
);
const TimeTrackingSettings = makeLazyComponent(() =>
  import("Settings/TimeTracking/TimeTrackingSettings")
);
const Documents = makeLazyComponent(() =>
  import("Settings/Documents/Documents")
);
const PersonalSettings = makeLazyComponent(() =>
  import("Settings/PersonalSettings/PersonalSettings")
);
const Reminders = makeLazyComponent(() => import("Reminders/Reminders"));
const BusinessTrips = makeLazyComponent(() => import("BusinessTrips/BusinessTripsTableForm"));
const Log = makeLazyComponent(() => import("Log/Log"));

const Test = makeLazyComponent(() => import("MainMenu/Test notifications"));
const Developer = makeLazyComponent(() => import("Developer/Developer"));

const Person = makeLazyComponent(() => import("Person/Person"));
const Rolls = makeLazyComponent(() => import("Security/Rolls"));

const Routes = ({ childProps }) => {
  return (
    <Switch>
      <AuthenticatedRoute
        path="/"
        exact
        component={Dashboard}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/changepw"
        exact
        component={NewPassword}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Dashboard"
        exact
        component={Dashboard}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/MonthCalendar"
        exact
        component={MonthCalendar}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/YearCalendar"
        exact
        component={YearCalendar}
        props={childProps}
      />
      {/* <AuthenticatedRoute
        path="/AbsenceCalendar"
        exact
        component={AbsenceCalendar}
        props={childProps}
      /> */}
      <AuthenticatedRoute
        path="/Persons"
        exact
        component={Persons}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/TimeTracking"
        exact
        component={TimeTracking}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Messages"
        exact
        component={Messages}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Articles"
        exact
        component={Articles}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Projects"
        exact
        component={Projects}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/CompanySettings"
        exact
        component={CompanySettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Addresses"
        exact
        component={Addresses}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/CalendarSettings"
        exact
        component={CalendarSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/OffDayTypes"
        exact
        component={OffDayTypes}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/TimeTrackingSettings"
        exact
        component={TimeTrackingSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Documents"
        exact
        component={Documents}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/GlobalSettings"
        exact
        component={GlobalSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/PersonalSettings"
        exact
        component={PersonalSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/ArticleSettings"
        exact
        component={ArticleSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Security"
        exact
        component={Rolls}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Reminders"
        exact
        component={Reminders}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/BusinessTrips"
        exact
        component={BusinessTrips}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Log"
        exact
        component={Log}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Test"
        exact
        component={Test}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Person"
        exact
        component={Person}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Developer"
        exact
        component={Developer}
        props={childProps}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={SignIn}
        props={childProps}
      />
    </Switch>
  );
};

Routes.propTypes = {
  childProps: PropTypes.object,
};

export default Routes;
